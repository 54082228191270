import styles from './navbar.module.scss';
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";

import ElectricBoltSharpIcon from '@mui/icons-material/ElectricBoltSharp';



import { Link } from "react-router-dom";

const Navbar = () => {
  // const { dispatch } = useContext(DarkModeContext);

  return (
    <div className={styles.navbar}>
      <div className={styles.wrapper}>

        <div className={styles.search}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{borderStyle: 'solid', borderWidth: '1px', borderRadius: '15px', color: 'white', borderColor: 'white', padding: '8px', display: 'flex', flexDirection: 'row'}}>
              <ElectricBoltSharpIcon style={{fontSize: 18, paddingLeft: 5, paddingRight: 10}}/>
              <div style={{paddingRight: 6}}>Bitcoin Lightning Network Statistics</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
