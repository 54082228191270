// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_home__9Ke73 {
  display: flex;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me {
  flex: 6 1;
  overflow: hidden;
  background-color: #EBECEF;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH {
  padding: 10px;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_widgets__1d9D4, .Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_charts__i0ALb {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_charts__i0ALb {
  overflow: hidden;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_listContainer__osNE\\+ {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_listTitle__M4Gw5 {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}
.Home_home__9Ke73 .Home_homeContainer__Gr0Me .Home_dashboard__TFFMH .Home_icon__-rTIr {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEE;EACE,SAAA;EACA,gBAAA;EACA,yBAAA;AAAJ;AAGI;EACE,aAAA;AADN;AAGM;EACE,aAAA;EACA,aAAA;EACA,SAAA;AADR;AAIM;EAEE,gBAAA;AAHR;AAMM;EAEE,sDAAA;EACA,aAAA;EACA,YAAA;AAJR;AAOM;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AALR;AAQM;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AANR","sourcesContent":[".home {\n  display: flex;\n  \n\n  .homeContainer {\n    flex: 6;\n    overflow: hidden;\n    background-color: #EBECEF;\n    \n\n    .dashboard {\n      padding: 10px;\n      \n      .widgets, .charts {\n        display: flex;\n        padding: 10px;\n        gap: 20px;\n      }\n  \n      .charts {\n        // padding: 5px 20px;\n        overflow: hidden; \n      }\n  \n      .listContainer {\n        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n        padding: 20px;\n        margin: 20px;\n      }\n      \n      .listTitle {\n        font-weight: 500;\n        color: gray;\n        margin-bottom: 15px;\n      }\n      \n      .icon {\n        font-size: 18px;\n        padding: 5px;\n        border-radius: 5px;\n        align-self: flex-end;\n      }\n\n\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `Home_home__9Ke73`,
	"homeContainer": `Home_homeContainer__Gr0Me`,
	"dashboard": `Home_dashboard__TFFMH`,
	"widgets": `Home_widgets__1d9D4`,
	"charts": `Home_charts__i0ALb`,
	"listContainer": `Home_listContainer__osNE+`,
	"listTitle": `Home_listTitle__M4Gw5`,
	"icon": `Home_icon__-rTIr`
};
export default ___CSS_LOADER_EXPORT___;
