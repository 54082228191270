import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styles from "./chart.module.scss";


const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

function getPercent(value, total) {
  // Check if value or total is not a number, or if total is 0
  if (isNaN(value) || isNaN(total) || total === 0) {
    return 0; // return 0 as a fallback, so the calculation doesn't fail
  }

  // If both checks are okay, proceed with the actual calculation
  return (value / total) * 100;
}




const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => result + entry.value, 0);

  // Sort the payload from highest to lowest value and filter out entries with a value of 0
  const sortedPayload = payload
    .filter(entry => entry.value !== 0)
    .sort((a, b) => b.value - a.value)
    .slice(0, 30);  // Only take the top X entries

  return (
    <div className="customized-tooltip-content" style={{ backgroundColor: 'white', padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 5 }}>
      <p className="total">{`${label} (Total: ${total.toFixed(2)})`}</p>
      <ul className="list">
        {sortedPayload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value.toFixed(2)} (${(+getPercent(entry.value, total)).toFixed(2)}%)`}
          </li>
        ))}
      </ul>
    </div>
  );
};








const renderLegend = (props) => {
  const { payload } = props;
  return (
    <div>
      {
        payload.reverse().map((entry, index) => (
          <span key={`item-${index}`}>
            <div style={{display:'inline-block', width:'12px', height:'12px', backgroundColor:entry.color, marginLeft: '12px', marginRight: '5px', borderRadius: '3px' }}>
            </div>
            {entry.value}
          </span>
        ))
      }
    </div>
  );
}




const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  return `rgb(${red},${green},${blue})`;
};


const PercentageAreaChart = ({ aspect, title, data }) => {

  // Extract data keys from the first data item, excluding the 'Date' key.
  const dataKeys = Object.keys(data[0]).filter(key => key !== 'Date');

  // Map each key to a random color.
  const colorMap = dataKeys.reduce((acc, key) => {
      acc[key] = getRandomColor();
      return acc;
  }, {});



  return (
    <div className={styles.chart}>
      <div className={styles.title} style={{fontWeight: 'bold', fontSize: 25, paddingLeft: 20}}>{title}</div>
      <ResponsiveContainer aspect={aspect}>
      <AreaChart
          width={500}
          height={400}
          data={data}
          stackOffset="expand"
          margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
          }}
      >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis tickFormatter={toPercent} />
          <Tooltip content={renderTooltipContent} />



          {
    <>

        <Area type="monotone" dataKey="Lower90Pcnt" stackId="1" stroke="#2eb227" fill="#2eb227" />
        <Area type="monotone" dataKey="TOP10Pcnt" stackId="1" stroke="#8884d8" fill="#8884d8" />
        

        <Area type="monotone" dataKey="Kiti" stackId="1" stroke="#010101" fill="#010101" />
        <Area type="monotone" dataKey="Kiti_ir_TOP" stackId="1" stroke="#808080" fill="#808080" />
        <Area type="monotone" dataKey="zion.fyi" stackId="1" stroke="#008000" fill="#008000" /> 
        <Area type="monotone" dataKey="mynodebtc.com" stackId="1" stroke="#FF0000" fill="#FF0000" /> 
        <Area type="monotone" dataKey="nodl.eu" stackId="1" stroke="#FFA500" fill="#FFA500" />
        

        <Area type="monotone" dataKey="bitfinex.com" stackId="1" stroke="#0077B5" fill="#0077B5" /> 
        <Area type="monotone" dataKey="lnbig.com" stackId="1" stroke="#4CAF50" fill="#4CAF50" />
        <Area type="monotone" dataKey="acinq.co" stackId="1" stroke="#FFC107" fill="#FFC107" />
        <Area type="monotone" dataKey="kraken.com" stackId="1" stroke="#1E1E1E" fill="#1E1E1E" />
        <Area type="monotone" dataKey="WalletOfSatoshi.com" stackId="1" stroke="#4A90E2" fill="#4A90E2" />
        {
          dataKeys.map(key => {
              if (  
                    key !== 'TOP10Pcnt' && 
                    key !== 'Lower90Pcnt' && 

                    key !== 'Kiti' && 
                    key !== 'Kiti_ir_TOP' &&
                    key !== 'zion.fyi' &&
                    key !== 'mynodebtc.com' &&
                    key !== 'nodl.eu' &&
                    

                    key !== 'bitfinex.com' &&
                    key !== 'lnbig.com' &&
                    key !== 'acinq.co' &&
                    key !== 'kraken.com' &&
                    key !== 'WalletOfSatoshi.com'
                    ) {
                  return (
                      <Area 
                          key={key}
                          type="monotone"
                          dataKey={key}
                          stackId="1"
                          stroke={colorMap[key]} 
                          fill={colorMap[key]} 
                      />
                  );
              }
              return null;
          })
        }
    </>
}





          {/*  
           
          
          <Area type="monotone" dataKey="river.com" stackId="1" stroke="#283593" fill="#283593" />
          <Area type="monotone" dataKey="nicehash.com" stackId="1" stroke="#00BCD4" fill="#00BCD4" />
          <Area type="monotone" dataKey="deezy.io" stackId="1" stroke="#E53935" fill="#E53935" />
          <Area type="monotone" dataKey="okx.com" stackId="1" stroke="#9C27B0" fill="#9C27B0" />
          <Area type="monotone" dataKey="Boltz" stackId="1" stroke="#1976D2" fill="#1976D2" />
          <Area type="monotone" dataKey="yalls.org" stackId="1" stroke="#009688" fill="#009688" />
          <Area type="monotone" dataKey="fixedfloat.com" stackId="1" stroke="#8BC34A" fill="#8BC34A" />
          <Area type="monotone" dataKey="okcoin" stackId="1" stroke="#00BCD4" fill="#00BCD4" />
          <Area type="monotone" dataKey="cyberdyne.sh" stackId="1" stroke="#9E9E9E" fill="#9E9E9E" />
          <Area type="monotone" dataKey="1sats.com" stackId="1" stroke="#7C4DFF" fill="#7C4DFF" />
          <Area type="monotone" dataKey="southxchange.com" stackId="1" stroke="#03A9F4" fill="#03A9F4" />
          <Area type="monotone" dataKey="bitrefill.com" stackId="1" stroke="#FF5722" fill="#FF5722" />
          <Area type="monotone" dataKey="OpenNode" stackId="1" stroke="#FFEB3B" fill="#FFEB3B" />
          <Area type="monotone" dataKey="Binance" stackId="1" stroke="#FFC107" fill="#FFC107" />
          <Area type="monotone" dataKey="mainnet.lightningconductor.net" stackId="1" stroke="#E040FB" fill="#E040FB" />
          <Area type="monotone" dataKey="CoinGate" stackId="1" stroke="#673AB7" fill="#673AB7" />
          <Area type="monotone" dataKey="1ML.com node ALPHA" stackId="1" stroke="#3F51B5" fill="#3F51B5" />
          <Area type="monotone" dataKey="LightningTo.Me" stackId="1" stroke="#2196F3" fill="#2196F3" />
          <Area type="monotone" dataKey="021c97a90a411ff2b10d" stackId="1" stroke="#0097A7" fill="#0097A7" />
          <Area type="monotone" dataKey="BCash_Is_Trash" stackId="1" stroke="#009688" fill="#009688" />
          <Area type="monotone" dataKey="lnd-02.mainnet" stackId="1" stroke="#43A047" fill="#43A047" />
          <Area type="monotone" dataKey="<EMPTY>" stackId="1" stroke="#606060" fill="#606060" /> */}
          {/* <Legend content={renderLegend}/> */}
      </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PercentageAreaChart;















