import Home from "./pages/Home/Home";
import Distribution from "./pages/Distribution/Distribution";

import { BrowserRouter as Router, Routes, Route, useLocation, Outlet } from "react-router-dom";
import axios from "axios";
import Connections from "./pages/Connections/Connections";
import GiniCoefficients from "./pages/Gini/Gini";
import LorenzCurvesByNodes from './pages/LorenzCurvesByNodes/LorenzCurvesByNodes';
import LorenzCurvesByEntities from "./pages/LorenzCurvesByEntities/LorenzCurvesByEntities";
import Distribution10Pcnt from "./pages/Distribution10Pcnt/Distribution10Pcnt";




axios.defaults.withCredentials = true;


export default function App() {


  return (
    <Router>
      <AnimationApp/>
    </Router>
  );
}




// https://codesandbox.io/s/react-router-v6-animated-routes-9zm9p?file=/src/App.tsx:1574-1607
function AnimationApp() {
  let location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route>
        
        <Route index element={<Home />} />
        <Route exact path="/distribution" element={ <Distribution/> }/>
        <Route exact path="/distribution10pcnt" element={ <Distribution10Pcnt/> }/>
        <Route exact path="/gini" element={ <GiniCoefficients/> }/>
        <Route exact path="/lorenzbynodes" element={ <LorenzCurvesByNodes/> }/>
        <Route exact path="/lorenzbyentities" element={ <LorenzCurvesByEntities/> }/>
        <Route exact path="/connections" element={ <Connections/> }/>

      </Route>
    </Routes>      
  );
}
