// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LorenzCurvesByNodes_home__Sh-Lq {
  display: flex;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF {
  flex: 6 1;
  overflow: hidden;
  background-color: #EBECEF;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM {
  padding: 10px;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_widgets__vvSEE, .LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_charts__vJ6Eb {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_charts__vJ6Eb {
  overflow: hidden;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_listContainer__5dOB1 {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_listTitle__lGV8G {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}
.LorenzCurvesByNodes_home__Sh-Lq .LorenzCurvesByNodes_homeContainer__LYwyF .LorenzCurvesByNodes_dashboard__L4kQM .LorenzCurvesByNodes_icon__wLJ5M {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/LorenzCurvesByNodes/LorenzCurvesByNodes.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,SAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AAEI;EACE,aAAA;AAAN;AAEM;EACE,aAAA;EACA,aAAA;EACA,SAAA;AAAR;AAGM;EAEE,gBAAA;AAFR;AAKM;EAEE,sDAAA;EACA,aAAA;EACA,YAAA;AAHR;AAMM;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AAJR;AAOM;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AALR","sourcesContent":[".home {\n  display: flex;\n\n  .homeContainer {\n    flex: 6;\n    overflow: hidden;\n    background-color: #EBECEF;\n    \n\n    .dashboard {\n      padding: 10px;\n      \n      .widgets, .charts {\n        display: flex;\n        padding: 10px;\n        gap: 20px;\n      }\n  \n      .charts {\n        // padding: 5px 20px;\n        overflow: hidden; \n      }\n  \n      .listContainer {\n        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n        padding: 20px;\n        margin: 20px;\n      }\n      \n      .listTitle {\n        font-weight: 500;\n        color: gray;\n        margin-bottom: 15px;\n      }\n      \n      .icon {\n        font-size: 18px;\n        padding: 5px;\n        border-radius: 5px;\n        align-self: flex-end;\n      }\n\n\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `LorenzCurvesByNodes_home__Sh-Lq`,
	"homeContainer": `LorenzCurvesByNodes_homeContainer__LYwyF`,
	"dashboard": `LorenzCurvesByNodes_dashboard__L4kQM`,
	"widgets": `LorenzCurvesByNodes_widgets__vvSEE`,
	"charts": `LorenzCurvesByNodes_charts__vJ6Eb`,
	"listContainer": `LorenzCurvesByNodes_listContainer__5dOB1`,
	"listTitle": `LorenzCurvesByNodes_listTitle__lGV8G`,
	"icon": `LorenzCurvesByNodes_icon__wLJ5M`
};
export default ___CSS_LOADER_EXPORT___;
