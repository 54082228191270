
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";


import ForceGraph2D from 'react-force-graph-2d';
import styles from './Connections.module.scss';

import Slider from '@mui/material/Slider';
import axios from "axios";
import { forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide } from 'd3-force';


// const data = {
//     "nodes": [
//       {"id": "Myriel", "group": 1},
//       {"id": "Napoleon", "group": 1},
//       {"id": "Mlle.Baptistine", "group": 1},
//       {"id": "Mme.Magloire", "group": 1},
//       {"id": "CountessdeLo", "group": 1},
//       {"id": "Geborand", "group": 1},
//       {"id": "Champtercier", "group": 1},
//       {"id": "Cravatte", "group": 1},
//       {"id": "Count", "group": 1},
//       {"id": "OldMan", "group": 1},
//       {"id": "Labarre", "group": 2}
//     ],
//     "links": [
//       {"source": "Napoleon", "target": "Myriel", "value": 1},
//       {"source": "Mlle.Baptistine", "target": "Myriel", "value": 8},
//       {"source": "Mme.Magloire", "target": "Myriel", "value": 10},
//       {"source": "Mme.Magloire", "target": "Mlle.Baptistine", "value": 6},
//     ]
//   };



const Connections = () => {

  const currentDate = new Date();
  const startDate = new Date('2018-12-01');

  const monthsDifference = (date1, date2) => {
    let months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  };

  const totalMonths = monthsDifference(startDate, currentDate);

  const [slider_selectedDate, setSlider_selectedDate] = useState(startDate);

  const handleSlider_selectedDate = (event, months) => {
    const newDate = new Date(startDate);
    newDate.setMonth(startDate.getMonth() + months);
    setSlider_selectedDate(newDate);
    // console.log(slider_selectedDate);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-01`;
  };



  /////////////////     Retrieve Data      /////////////////
  const [rawConnectionsData, setRawConnectionsData] = useState([]);
  
  async function getRawConnectionsData() {
    try {
      const response = await axios.get("/api/chart/liquidityconnections");
      setRawConnectionsData(response.data);
    } catch (error) {}
  }

  useEffect(() => {
    getRawConnectionsData();
  }, []);





  /////////////////     Filter Based On Date      /////////////////
  function filterAndFormatData(data, selectedDate) {
    let nodesMap = {}; // To maintain unique nodes with group info
    let links = [];
  
    // Filter data based on selected date
    const filteredData = data.filter(item => item.date === selectedDate);
  
    filteredData.forEach(item => {
      // Add entities with group info to the map
      nodesMap[item.entity1] = item.entityGroup1;
      nodesMap[item.entity2] = item.entityGroup2;
  
      // Add links
      links.push({
        source: item.entity1,
        target: item.entity2,
        value: item.value,
      });
    });
  
    // Convert map to nodes array
    let nodes = Object.keys(nodesMap).map(entity => ({
      id: entity,
      group: nodesMap[entity],
    }));
  
    return {
      nodes: nodes,
      links: links,
    };
  }



  // Adjust data based on selected date
  const [connectionsData, setConnectionsData] = useState({"nodes": [], "links": []});
  useEffect(() => {
    console.log(formatDate(slider_selectedDate));
    if(rawConnectionsData.length > 0)
      setConnectionsData();
  }, [slider_selectedDate, rawConnectionsData]);







  const [graphNodes, setGraphNodes] = useState([]);
  const [graphLinks, setGraphLinks] = useState([]);

  let nodePositions = {};

  function updateNodePositions(nodes) {
      nodes.forEach(node => {
          nodePositions[node.id] = {
              x: node.x,
              y: node.y
          };
      });
  }

  function warmUpSimulation(nodes, links) {
      // Provide hints for starting positions based on stored positions
      nodes.forEach(node => {
          if (nodePositions[node.id]) {
              node.x = nodePositions[node.id].x;
              node.y = nodePositions[node.id].y;
          }
      });

      const simulation = forceSimulation(nodes)
          .force("link", forceLink(links).id(d => d.id).distance(80))
          .force("charge", forceManyBody())
          .force("center", forceCenter())
          .force("collide", forceCollide().radius(d => d.radius).iterations(2))
          .stop();

      // Run the simulation off-screen for a set number of iterations
      for (let i = 0; i < 600; ++i) simulation.tick();

      // Update node positions after warm-up
      updateNodePositions(nodes);

      return nodes; // Return updated nodes with new positions
  }

  // Watch for changes in your data, e.g., from the slider
  useEffect(() => {
      const newData = filterAndFormatData(rawConnectionsData, formatDate(slider_selectedDate));

      const nodes = newData.nodes;
      const links = newData.links;

      const warmedUpNodes = warmUpSimulation(nodes, links);

      setGraphNodes(warmedUpNodes);
      setGraphLinks(links);
  }, [slider_selectedDate, rawConnectionsData]);









  const customLinkForce = links => {
    const linkForce = forceLink(links)
        .id(d => d.id)
        .distance(link => 100 / (1 + link.value * 1000000))
        .strength(1);  // Adjust if necessary
    
    return simulation => {
      simulation.force("link", linkForce);
    };
  };






  return (
    <div className={styles.home}>
      <Sidebar />
      <div className={styles.homeContainer}>
        <Navbar />
        <div className={styles.dashboard} >

          <div style={{background: 'white', padding: 20}}>

            <div style={{marginBottom: 20, fontWeight: 'bold'}}>TOP 50 Entity Connections (By Capacity)</div>

            <div style={{ padding: 20, border: '1px solid grey', borderRadius: 15 }}>
              <Typography id="non-linear-slider" gutterBottom>
                Date: {formatDate(slider_selectedDate)}
              </Typography>
              <Slider
                defaultValue={0}
                min={0}
                step={1}
                max={60} // 5 years = 60 months
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const date = new Date(startDate);
                  date.setMonth(startDate.getMonth() + value);
                  return formatDate(date);
                }}
                onChange={handleSlider_selectedDate}
                sx={{
                  color: 'rgb(123, 0, 63)',
                  "&:hover": {
                    color: 'rgb(230, 65, 100)',
                  },
                }}
              />

              
            </div>

              


            <ForceGraph2D
              d3AlphaDecay={0.0328}
              d3VelocityDecay={0.7}
              d3Force={(forceName, forceFn) => {
                if (forceName === "link") return customLinkForce(connectionsData.links);
                return forceFn;
              }}
              forceFn={() => forceManyBody().strength(-40000)}

              // graphData={connectionsData}
              graphData={{ nodes: graphNodes, links: graphLinks }}
              nodeAutoColorBy="group"
              linkWidth={link => Math.sqrt(link.value)} // Adjusts link width based on value. You can customize this function.

              nodeCanvasObject={(node, ctx, globalScale) => {
                const label = node.id;
                const fontSize = 2;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const radius = Math.sqrt(textWidth * textWidth / 4 + fontSize * fontSize / 4) + fontSize * 0.6; // pythagorean theorem + some padding
        
                // Draw the bubble
                ctx.beginPath();
                ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = node.color;
                ctx.fill();
        
                // Draw the label with black color
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = '#000'; // black color for text
                ctx.fillText(label, node.x, node.y);
        
                node.__radius = radius; // to re-use in nodePointerAreaPaint
              }}
              linkCanvasObject={(link, ctx, globalScale) => {
                  // Draw the link line with dynamic width based on value
                  ctx.beginPath();
                  ctx.strokeStyle = link.color || '#f0f0f0';
                  ctx.lineWidth = Math.sqrt(link.value) / 10; // Adjust link width based on value
                  ctx.moveTo(link.source.x, link.source.y);
                  ctx.lineTo(link.target.x, link.target.y);
                  ctx.stroke();

                  // Draw the link label (value)
                  const label = link.value.toString();
                  const fontSize = 3;
                  ctx.font = `${fontSize}px Sans-Serif`;
                  ctx.fillStyle = '#000'; // Black color for text
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  const labelPosition = {
                      x: (link.source.x + link.target.x) / 2,
                      y: (link.source.y + link.target.y) / 2
                  };
                  ctx.fillText(label, labelPosition.x, labelPosition.y);
              }}
              nodePointerAreaPaint={(node, color, ctx) => {
                ctx.fillStyle = color;
                if (node.__radius) {
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, node.__radius, 0, 2 * Math.PI, false);
                    ctx.fill();
                }
              }}
            />



          </div>

        </div>
      </div>
    </div>

    
  )
};


export default Connections;