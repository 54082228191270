import styles from "./chart.module.scss";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar } from "recharts";


const renderLegend = (props) => {
  const { payload } = props;
  // console.log(payload);
  return (
    <div>
      {
        payload.reverse().map((entry, index) => (
          <span key={`item-${index}`}>
            <div style={{display:'inline-block', width:'12px', height:'12px', backgroundColor:entry.color, marginLeft: '12px', marginRight: '5px', borderRadius: '3px' }}>
            </div>
            {entry.value}
          </span>
        ))
      }
    </div>
  );
}


const LorenzCurveChart = ({ aspect, title, data }) => {
  return (
    <div className={styles.chart}>
      <div className={styles.title}>{title}</div>
      <ResponsiveContainer aspect={aspect}>
        <AreaChart width={730} height={250} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }} >
          <defs>
           
            <linearGradient id="y" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2eb227" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2eb227" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="x" stroke="gray" angle={5} dy={15} height={60}/>
          <YAxis type="number"/>
          <CartesianGrid strokeDasharray="3 3" className={styles.chartGrid}/>
          <Tooltip />
          <Area name="" type="monotone" dataKey="y" stroke="#2eb227" fillOpacity={1} fill="url(#y)" />
          <Legend content={renderLegend}/>
        </AreaChart>
        
      </ResponsiveContainer>
    </div>
  );
};

export default LorenzCurveChart;
