// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LorenzCurvesByEntities_home__B2hbd {
  display: flex;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E {
  flex: 6 1;
  overflow: hidden;
  background-color: #EBECEF;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ {
  padding: 10px;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_widgets__sYimy, .LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_charts__\\+04M- {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_charts__\\+04M- {
  overflow: hidden;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_listContainer__SKssK {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_listTitle__yWS2m {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}
.LorenzCurvesByEntities_home__B2hbd .LorenzCurvesByEntities_homeContainer__q\\+E1E .LorenzCurvesByEntities_dashboard__edJpQ .LorenzCurvesByEntities_icon__Vw06r {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/LorenzCurvesByEntities/LorenzCurvesByEntities.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,SAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AAEI;EACE,aAAA;AAAN;AAEM;EACE,aAAA;EACA,aAAA;EACA,SAAA;AAAR;AAGM;EAEE,gBAAA;AAFR;AAKM;EAEE,sDAAA;EACA,aAAA;EACA,YAAA;AAHR;AAMM;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AAJR;AAOM;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AALR","sourcesContent":[".home {\n  display: flex;\n\n  .homeContainer {\n    flex: 6;\n    overflow: hidden;\n    background-color: #EBECEF;\n    \n\n    .dashboard {\n      padding: 10px;\n      \n      .widgets, .charts {\n        display: flex;\n        padding: 10px;\n        gap: 20px;\n      }\n  \n      .charts {\n        // padding: 5px 20px;\n        overflow: hidden; \n      }\n  \n      .listContainer {\n        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n        padding: 20px;\n        margin: 20px;\n      }\n      \n      .listTitle {\n        font-weight: 500;\n        color: gray;\n        margin-bottom: 15px;\n      }\n      \n      .icon {\n        font-size: 18px;\n        padding: 5px;\n        border-radius: 5px;\n        align-self: flex-end;\n      }\n\n\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `LorenzCurvesByEntities_home__B2hbd`,
	"homeContainer": `LorenzCurvesByEntities_homeContainer__q+E1E`,
	"dashboard": `LorenzCurvesByEntities_dashboard__edJpQ`,
	"widgets": `LorenzCurvesByEntities_widgets__sYimy`,
	"charts": `LorenzCurvesByEntities_charts__+04M-`,
	"listContainer": `LorenzCurvesByEntities_listContainer__SKssK`,
	"listTitle": `LorenzCurvesByEntities_listTitle__yWS2m`,
	"icon": `LorenzCurvesByEntities_icon__Vw06r`
};
export default ___CSS_LOADER_EXPORT___;
