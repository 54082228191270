// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Connections_home__hZ-uX {
  display: flex;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- {
  flex: 6 1;
  overflow: hidden;
  background-color: #EBECEF;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu {
  padding: 10px;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_widgets__Q2zca, .Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_charts__VwTZN {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_charts__VwTZN {
  overflow: hidden;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_listContainer__D7dkz {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_listTitle__IXwey {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}
.Connections_home__hZ-uX .Connections_homeContainer__SYq6- .Connections_dashboard__p9nLu .Connections_icon__PpoWP {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Connections/Connections.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEE;EACE,SAAA;EACA,gBAAA;EACA,yBAAA;AAAJ;AAGI;EACE,aAAA;AADN;AAGM;EACE,aAAA;EACA,aAAA;EACA,SAAA;AADR;AAIM;EAEE,gBAAA;AAHR;AAMM;EAEE,sDAAA;EACA,aAAA;EACA,YAAA;AAJR;AAOM;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AALR;AAQM;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AANR","sourcesContent":[".home {\n  display: flex;\n  \n\n  .homeContainer {\n    flex: 6;\n    overflow: hidden;\n    background-color: #EBECEF;\n    \n\n    .dashboard {\n      padding: 10px;\n      \n      .widgets, .charts {\n        display: flex;\n        padding: 10px;\n        gap: 20px;\n      }\n  \n      .charts {\n        // padding: 5px 20px;\n        overflow: hidden; \n      }\n  \n      .listContainer {\n        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n        padding: 20px;\n        margin: 20px;\n      }\n      \n      .listTitle {\n        font-weight: 500;\n        color: gray;\n        margin-bottom: 15px;\n      }\n      \n      .icon {\n        font-size: 18px;\n        padding: 5px;\n        border-radius: 5px;\n        align-self: flex-end;\n      }\n\n\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `Connections_home__hZ-uX`,
	"homeContainer": `Connections_homeContainer__SYq6-`,
	"dashboard": `Connections_dashboard__p9nLu`,
	"widgets": `Connections_widgets__Q2zca`,
	"charts": `Connections_charts__VwTZN`,
	"listContainer": `Connections_listContainer__D7dkz`,
	"listTitle": `Connections_listTitle__IXwey`,
	"icon": `Connections_icon__PpoWP`
};
export default ___CSS_LOADER_EXPORT___;
