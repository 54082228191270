// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart_chart__wD-zJ {
  flex: 1 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  overflow: hidden;
  background-color: white;
  border-radius: 15px;
}
.chart_chart__wD-zJ .chart_title__tUpJz {
  margin-bottom: 20px;
}
.chart_chart__wD-zJ .chart_chartGrid__Nk0t2 {
  stroke: rgb(228, 225, 225);
}`, "",{"version":3,"sources":["webpack://./src/components/chart/chart.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EAEA,sDAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,0BAAA;AADJ","sourcesContent":[".chart {\n  flex: 1;\n  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n  padding: 10px;\n  color: gray;\n  overflow: hidden;\n  background-color: white;\n  border-radius: 15px;\n  \n\n  .title {\n    margin-bottom: 20px;\n  }\n\n  .chartGrid {\n    stroke: rgb(228, 225, 225);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `chart_chart__wD-zJ`,
	"title": `chart_title__tUpJz`,
	"chartGrid": `chart_chartGrid__Nk0t2`
};
export default ___CSS_LOADER_EXPORT___;
