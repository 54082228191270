import styles from "./chart.module.scss";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar } from "recharts";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState, useEffect } from "react";
import axios from "axios";


const renderLegend = (props) => {
  const { payload } = props;
  return (
    <div>
      {
        payload.reverse().map((entry, index) => (
          <span key={`item-${index}`}>
            <div style={{display:'inline-block', width:'12px', height:'12px', backgroundColor:entry.color, marginLeft: '12px', marginRight: '5px', borderRadius: '3px' }}>
            </div>
            {entry.value}
          </span>
        ))
      }
    </div>
  );
}


const SingleLineChart = ({ aspect, title, XName, YName, YStartFrom=0, YEndsAt="dataMax", data }) => {
  

  return (
    <div className={styles.chart}>
      <div className={styles.title}>{title}</div>
      <ResponsiveContainer aspect={aspect}>
        
        <AreaChart width={730} height={250} data={data.chartdata} margin={{ top: 10, right: 30, left: 40, bottom: 0 }}>
          {/* <rect x={0} y={0} width={730} height={250}  /> */}
          <defs>
            <linearGradient id={YName} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2eb227" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2eb227" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey={XName} stroke="gray" tickCount={5} angle={5} dy={15} height={50}/>
          {/* <YAxis type="number" domain={['dataMin - 0.5', 'dataMax + 0.5']}  unit="" tick={{ width: 250 }}/> */}
          <YAxis type="number" domain={[YStartFrom, YEndsAt]} tick={{ width: 100 }}/>
          <CartesianGrid strokeDasharray="3 3" className={styles.chartGrid}/>
          <Tooltip />
          <Area name={YName} unit=" " type="monotone" dataKey={YName} stroke="#2eb227" fillOpacity={1} fill={`url(#${YName})`} />
          <Legend content={renderLegend}/>
        </AreaChart>
        
      </ResponsiveContainer>
    </div>
  );
};

export default SingleLineChart;
